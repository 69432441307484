<template>
    <div>
        <div style="height: 20px;"></div>
        <!--详情-->
        <el-row>
            <el-col :span="16">
                <el-form v-loading="loading" size="medium" ref="form" :label-width="this.env.label_width">
                    <el-form-item label="活动名称">
                        <span>{{ info.activity_title }}</span>
                    </el-form-item>
                    <el-form-item label="运动品类">
                        <div class="form-tag_colour-div">
                            <el-tag :key="item.id" v-for="item in info.motion_category_two" class="form-tag_colour-tag"
                                effect="dark">
                                {{ item.name }}
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item label="运动效果">
                        <div class="form-tag_colour-div">
                            <el-tag :key="item.id" v-for="item in info.target_category_zero" class="form-tag_colour-tag"
                                effect="dark">
                                {{ item.name }}
                            </el-tag>
                        </div>
                    </el-form-item>
                    <el-form-item label="活动形式">
                        <el-tag v-if="info.activity_type === 1">线下</el-tag>
                        <el-tag v-if="info.activity_type === 2" type="success">线上</el-tag>
                    </el-form-item>
                    <el-form-item v-if="info.activity_type === 1" label="活动地址">
                        <span>{{ info.city }}{{ info.county }}{{ info.road }}{{ info.address }}</span>
                        <div style="height: 20px;"></div>
                        <Amap ref="amap_edit" idindex="address" style="width:300px; height: 200px;" />
                    </el-form-item>
                    <el-form-item label="联系方式">
                        <span>{{ info.activity_phone }}</span>
                    </el-form-item>
                    <el-form-item v-if="info.brand_uuid" label="指定品牌">
                        <el-tag class="form-tag_colour-tag" type="info" size="mini">
                            {{ info.brand.brand_name }}
                        </el-tag>
                    </el-form-item>
                    <el-form-item label="指定门店" v-if="!is_empty(info.store_uuid_all)">
                        <el-tag :key="item.index" v-for="item in info.store_uuid_all" class="form-tag_colour-tag"
                            type="info" size="mini">
                            {{ item.store_name }}
                        </el-tag>
                    </el-form-item>
                    <el-form-item v-if="returnSkuState()" label="规格信息">
                        <section class="specs__table">
                            <div class="specs__table-header">
                                <section class="specs__table-title">
                                    <p>规格标题</p>
                                </section>
                                <section class="specs__table-title">
                                    <p>价格（元）</p>
                                </section>
                                <section class="specs__table-title">
                                    <p>人数上限</p>
                                </section>
                            </div>
                            <div class="specs__table-content">
                                <section v-for="(item, index) in sku_data" :key="index">
                                    <div v-if="item.sku_name && item.sku_state === 1" class="specs__table-cell">
                                        <div class="specs__table-title">
                                            <p>{{ item.sku_name }}</p>
                                        </div>
                                        <div class="specs__table-title">
                                            <span class="form-span-text">{{ item.sku_price }}</span>
                                        </div>
                                        <div class="specs__table-title">
                                            <span class="form-span-text">{{ item.sku_qty }}</span>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </section>
                    </el-form-item>
                    <el-form-item label="人数限制">
                        <span>{{ info.quota }}人</span>
                    </el-form-item>
                    <el-form-item label="报名时间">
                        <span>{{ info.signup_start_time }}至{{ info.signup_end_time }}</span>
                    </el-form-item>
                    <el-form-item label="活动时间">
                        <div v-if="this.info.time_type == 3">
                            {{ info.start_time }}至{{ info.end_time }}
                        </div>
                        <div v-else>
                            <div v-for="(item, index) in this.info.detailed_time" :key="index">
                                第{{ index + 1 }}天：
                                {{ item.activity_date }} {{ item.activity_time[0] }}至{{ item.activity_time[1] }}
                            </div>
                        </div>
                    </el-form-item>
                    <el-form-item v-if="!is_empty(info.video)" label="活动视频">
                        <Qnupload v-model="info.video" type="video" :readonly='true' />
                    </el-form-item>
                    <el-form-item label="活动照片">
                        <Qnupload v-model="info.photo" :sum="5" :readonly='true' />
                    </el-form-item>
                    <el-form-item label="原价">
                        <span>￥{{ info.originally_price }}</span>
                    </el-form-item>
                    <el-form-item label="售价" v-if="info.promotion_price > 0">
                        <span>￥{{ info.promotion_price }}</span>
                    </el-form-item>
                    <el-form-item label="退款规则">
                        <span v-if="info.is_refund === 1">{{ info.is_refund_end_time }}前可退款</span>
                        <span v-else>不可退款</span>
                    </el-form-item>
                    <el-form-item label="活动简介">
                        <span> {{ info.introduce }}</span>
                    </el-form-item>
                    <el-form-item label="注意事项">
                        <span> {{ info.attention }}</span>
                    </el-form-item>
                    <el-form-item label="详情">
                        <!-- 富文本编辑框 -->
                        <div v-html="info.details_rich_text" class="rich-text__container" />
                    </el-form-item>
                    <el-form-item>
                        <el-button size="medium" @click="isreturn">返回
                        </el-button>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
    </div>
</template>
<script>
// import Wangeditor from '@/components/Wangeditor'
import Qnupload from '@/components/Qnupload'
// import Region from '@/components/Region'
import Amap from '@/components/Amap'

export default {
    components: {
        // Wangeditor,
        Qnupload,
        // Region,
        Amap,
    },
    data() {
        return {
            loading: true,
            info: {},
            activity_uuid: '',
            // 规格信息
            sku_data: [],
            // 规格类型 0--> 无规格 1--> 多规格
            sku_type: 0,
        }
    },
    // 创建
    created() {
        this.init()
    },
    // 安装
    mounted() {
    },
    methods: {
        // 判断是否为空
        is_empty(auth) {
            return this.Tool.is_empty(auth)
        },
        // 权限判断
        is_auth(auth) {
            return this.Tool.is_auth(auth)
        },
        // 初始化
        init() {
            let activity_uuid = this.$route.query.activity_uuid
            if (!this.Tool.is_empty(activity_uuid)) {
                this.activity_uuid = activity_uuid
                this.getinfo(activity_uuid)
            } else {
                this.$message({
                    message: '无查找对象',
                    duration: this.env.message_error,
                    onClose: () => {
                        this.$router.push({ path: '/product/activity' })    // 返回列表
                    }
                });
            }
        },
        //获取详情
        getinfo(activity_uuid) {
            let postdata = {
                api_name: "product.activity.getinfo",
                token: this.Tool.get_l_cache('token'),
                activity_uuid
            }
            // console.log('postdata', postdata)
            this.loading = true
            let thi = this
            this.Tool.post_data('oss', postdata, (json) => {
                this.loading = false
                // console.log('json', json)
                if (json.code === 0) {
                    this.signup_time = [json.data.signup_start_time, json.data.signup_end_time]
                    this.info = json.data

                    // 地址地图处理
                    setTimeout(function () {
                        if (thi.info.activity_type === 1) {
                            thi.getposition()           // 地图定点
                        }
                    }, 1000);
                    if (json.data.sku_data.length > 0) {
                        this.sku_data = json.data.sku_data;
                    }
                } else {
                    this.Tool.errormes(json)
                }
            })
        },
        // 获取坐标
        getposition() {
            // if (this.Tool.is_empty(this.info.road)) {
            //     this.Tool.errormes({ code: 10005, message: '请输入路名' })
            // } else {
            let thi = this
            let address = this.info.city + this.info.road + this.info.address

            this.$refs.amap_edit.address2position(address, (json) => {
                if (json) {
                    if (thi.Tool.is_empty(json.lng)) {
                        thi.Tool.errormes({ code: 10005, message: '未查询到坐标信息' })
                    }
                    this.$refs.amap_edit.marker([json.lng, json.lat, thi.info.store_name])
                } else {
                    this.$message({
                        message: '根据地址查询位置失败', type: 'error',
                        duration: this.env.message_error,
                    });
                }
            });
            // }

        },
        // 返回
        isreturn() {
            this.$router.push({ path: '/product/activity' })
        },
        // 返回是否有设置规格
        returnSkuState() {
            const { sku_data } = this;
            let count = 0;
            if (sku_data.length === 0) {
                return false;
            } else {
                for (let i in sku_data) {
                    if (sku_data[i].sku_state === 0) {
                        count += 1;
                    }
                }
                if (count === sku_data.length) {
                    return false;
                } else {
                    return true;
                }
            }
        },
    }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
